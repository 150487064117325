@charset "utf-8";
@import "projectset";
@import "util/mixins";


html,body {
	width: 100%;
	height: 100%;
}
* {
	margin: 0;
	padding :0;
}
a {
	text-decoration: none;
	color: $BaseCol;
}

a:hover {
	color: $BaseCol + $colorDiff;
}

.page--wrapper {
	position: relative;
	width: 100vw;
	height: 100vh;
	min-height: 320px;
	font-family: "lato";
	font-weight: 400;
	background: #f3f3f3;
	color:#666;
	box-sizing: border-box;
	border: 6px solid $BaseCol;
	display: flex;
	align-items: center;
	justify-content: left;
}
.section-top {
	position: relative;
	width: auto;
	height: auto;
	text-align: left;
	margin-left: 8%;
}
.section-top--title {
	padding-top: 20%;
	font-size: 160%;
	font-weight: 400;
	span { display: inline-block; }
	.typo__en {
		padding-right: 0.2em;
	}
	.typo__ja {
		font-size: 80%;
		font-weight: normal;
	}
}
.profession {
	padding-top: 10vh;
}
.resume_title {
	font-size: 100%;
	font-weight: 400;
	padding-bottom: 0.5em;
}
.section-top--text {
	padding-top: 18vh;
	font-size: 120%;
	a {
		display: inline-block;
		border-bottom: 1px solid $BaseCol;
	}
	.sepalate {
		display: inline-block;
		padding: 0 0.5em;
	}
}
.copyright {
	position: absolute;
	bottom: 5%;
	left: 8%;
}

@import "veil";