@import "util/mixins";

.page--wrapper {
	border-top: 40px solid #00cfc5;
	border-bottom: 40px solid #00cfc5;
	border-right: 40px solid #00cfc5;
	border-left: 40px solid #00cfc5;
	transition: border 600ms $ease-in-out-quart;
	transition-delay: 800ms;
}
.page--wrapper.is-open {
	border-top: 6px solid #00cfc5;
	border-bottom: 6px solid #00cfc5;
	border-right: 6px solid #00cfc5;
	border-left: 6px solid #00cfc5;
}
