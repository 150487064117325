// Project Settings

//** FontSet **
//----------------------------------

//** ImagePass **
//----------------------------------
// $ImgPath: "/img/common/";

//** Moduls **
//----------------------------------

// Header Hight
$g_header-pri-H: 49px;
$g_header-sec-H: 40px;
$g_header-H_sp: 36px;

// Base Module 208x104px

// Contents Width
$InnerMinWidth: 1040px; // 208*5
$InnerMaxWidth: 1456px; // 208*7
$InnerMinWidth_sp: 320px; // iPhone5 Screen Size
$InnerMaxWidth_sp: 414px; // iPhone6 Plus Screen Size
$BreakPoint: 520px;


//** Color Scheme **
//----------------------------------
$BaseCol:	#00cfc5;
$white:		#ffffff;
$Gray05:	#EEEEEE;
$Gray10:	#E5E5E5;
$Gray20:	#CCCCCC;
$Gray30:	#AAAAAA;
$Gray40:	#999999;
$Gray60:	#666666;
$Gray80:	#333333;
$black_alt:	#111111;
$black:		#000000;
$baseTextColor: #111111;
$baseTextGray: #3c3c3c;
$colorDiff: #171717;

//** mixin_modules **
//----------------------------------
